<!-- contacts -->
<template>
  <div class="contacts-page">
    <!-- section1 -->
    <div class="section-list">
      <div class="section-list-header">
        <div class="section-list-header-left">
          <slot name="icon"></slot>
          <div class="title">Family</div>
        </div>
      </div>
      <div class="section-list-main">
        <ul class="content" v-loading="familyDialog.loading">
          <!-- people -->
          <template v-if="pageType === '0'">
<!--            <li v-for="(item, index) in familyList" class="item" :key="index">
              <head-img
                :firstName="item.firstName"
                :lastName="item.lastName"
                class="user-box"
                :src="item.photo"
                line-height="88"
              ></head-img>
              <p class="text">{{ item.firstName }} {{ item.lastName }}</p>
              <p class="connections">{{ item.releationship }}</p>
              <div
                class="btn"
                :class="{
                  green: item.parentStatus == 'Connected',
                  red: item.parentStatus == 'DisConnected'
                }"
              >
                {{ item.parentStatus }}
              </div>
            </li> -->
			<li v-for="(item, index) in familyList" class="item" :key="index" v-if="item.isMaster == '1'">
			  <span class="starImg" >
			    <img :src="require('@/assets/images/star.png')" alt="" />
			  </span>
			  <head-img
			    :firstName="item.firstName"
			    :lastName="item.lastName"
			    class="user-box"
			    :src="item.photo"
			    line-height="88"
			    @click="isImgCutShow = true"
			  ></head-img>
			  <p class="text" @click="linkToParent1(item)">
			    {{ item.firstName }} {{ item.lastName }}
			  </p>
			  <p class="connections">{{ item.releationship }}</p>
			  <div
			    class="btn"
			    :class="{
			      green: item.parentStatus == 'Connected',
			      red: item.parentStatus == 'DisConnected',
				  Connected:item.parentStatus == 'Connected',
				   Disconnected:item.parentStatus == 'Disconnected'
			    }"
			    @click="handleFun(item.parentStatus, item)"
			  >
			    {{ item.parentStatus }}
			  </div>
			</li>
			<!-- 再次循环除主家长外的 start-->
			<li v-for="(item, index) in familyList" class="item" :key="index" v-if="item.isMaster != '1'">
			  <!-- <span class="starImg" >
			    <img :src="require('@/assets/images/star.png')" alt="" />
			  </span> -->
			  <head-img
			    :firstName="item.firstName"
			    :lastName="item.lastName"
			    class="user-box"
			    :src="item.photo"
			    line-height="88"
			    @click="isImgCutShow = true"
			  ></head-img>
			  <p class="text" @click="linkToParent(item)">
			    {{ item.firstName }} {{ item.lastName }}
			  </p>
			  <p class="connections">{{ item.releationship }}</p>
			  <div
			    class="btn"
			    :class="{
			      green: item.parentStatus == 'Connected',
			      red: item.parentStatus == 'DisConnected',
				  Connected:item.parentStatus == 'Connected',
				   Disconnected:item.parentStatus == 'Disconnected'
			    }"
			    @click="handleFun(item.parentStatus, item)"
			  >
			    {{ item.parentStatus }}
			  </div>
			</li>
			<!-- end -->
			<li
			  class="item"
			  v-if="familyList.length < 4"
			  v-show="
			    $buttonPermission('Students contacts', 'Full') ||
			    $buttonPermission('Students contacts', 'Edit') ||
			    $buttonPermission('Students contacts', 'Create')
			  "
			  @click="openFamilyDialog"
			>
			  <el-tooltip
			    popper-class="edoovo-table-cell-tooltip"
			    content="Add family"
			    :visible-arrow="false"
			  >
			    <div class="addMenu">+</div></el-tooltip
			  >
			  <!-- <div class="btn addBtn">Add family</div> -->
			</li>
          </template>
          <!-- normal -->
          <template v-if="pageType === '1'">
            <li v-for="(item, index) in familyList" class="item" :key="index" v-if="item.isMaster == '1'">
              <span class="starImg" >
                <img :src="require('@/assets/images/star.png')" alt="" />
              </span>
              <head-img
                :firstName="item.firstName"
                :lastName="item.lastName"
                class="user-box"
                :src="item.photo"
                line-height="88"
                @click="isImgCutShow = true"
              ></head-img>
              <p class="text" @click="linkToParent(item)">
                {{ item.firstName }} {{ item.lastName }}
              </p>
              <p class="connections">{{ item.releationship }}</p>
              <div
                class="btn"
                :class="{
                  green: item.parentStatus == 'Connected',
                  red: item.parentStatus == 'DisConnected',
				  Connected:item.parentStatus == 'Connected',
				   Disconnected:item.parentStatus == 'Disconnected'
                }"
                @click="handleFun(item.parentStatus, item)"
              >
                {{ item.parentStatus }}
              </div>
            </li>
			<!-- 再次循环除主家长外的 start-->
			<li v-for="(item, index) in familyList" class="item" :key="index" v-if="item.isMaster != '1'">
			  <!-- <span class="starImg" >
			    <img :src="require('@/assets/images/star.png')" alt="" />
			  </span> -->
			  <head-img
			    :firstName="item.firstName"
			    :lastName="item.lastName"
			    class="user-box"
			    :src="item.photo"
			    line-height="88"
			    @click="isImgCutShow = true"
			  ></head-img>
			  <p class="text" @click="linkToParent(item)">
			    {{ item.firstName }} {{ item.lastName }}
			  </p>
			  <p class="connections">{{ item.releationship }}</p>
			  <div
			    class="btn"
			    :class="{
			      green: item.parentStatus == 'Connected',
			      red: item.parentStatus == 'DisConnected',
				  Connected:item.parentStatus == 'Connected',
				   Disconnected:item.parentStatus == 'Disconnected'
			    }"
			    @click="handleFun(item.parentStatus, item)"
			  >
			    {{ item.parentStatus }}
			  </div>
			</li>
			<!-- end -->
            <li
              class="item"
              v-if="familyList.length < 4"
              v-show="
                $buttonPermission('Students contacts', 'Full') ||
                $buttonPermission('Students contacts', 'Edit') ||
                $buttonPermission('Students contacts', 'Create')
              "
              @click="openFamilyDialog"
            >
              <el-tooltip
                popper-class="edoovo-table-cell-tooltip"
                content="Add family"
                :visible-arrow="false"
              >
                <div class="addMenu">+</div></el-tooltip
              >
              <!-- <div class="btn addBtn">Add family</div> -->
            </li>
          </template>
        </ul>
      </div>
    </div>
    <!-- section2 -->
    <div class="section-list">
      <div class="section-list-header">
        <div class="section-list-header-left">
          <slot name="icon"></slot>
          <div class="title">Pickup</div>
        </div>
      </div>
      <div class="section-list-main">
        <ul class="content" v-loading="pickupDialog.loading">
          <li
            v-if="pageType === '0'"
            v-for="(item, index) in pickupList"
            class="item"
            :key="index"
          >
            <head-img
              :firstName="item.firstName"
              :lastName="item.lastName"
              class="user-box"
              :src="item.photo"
              line-height="88"
            ></head-img>
            <p class="text">{{ item.firstName }} {{ item.lastName }}</p>
            <p class="connections">{{ item.releationship }}</p>
          </li>
          <template v-if="pageType === '1'">
            <li v-for="(item, index) in pickupList" class="item" :key="index">
              <head-img
                :firstName="item.firstName"
                :lastName="item.lastName"
                class="user-box"
                :src="item.photo"
                line-height="88"
                @click="isImgCutShow = true"
              ></head-img>
              <p class="text" @click="previewDetail(item)">
                {{ item.firstName }} {{ item.lastName }}
              </p>
              <p class="connections">{{ item.releationship }}</p>
            </li>
            <li
              class="item"
              v-if="pickupList.length < 4"
              @click="openPickupDialog"
              v-show="
                $buttonPermission('Students contacts', 'Full') ||
                $buttonPermission('Students contacts', 'Edit') ||
                $buttonPermission('Students contacts', 'Create')
              "
            >
              <el-tooltip
                popper-class="edoovo-table-cell-tooltip"
                content="Add pickup"
                :visible-arrow="false"
              >
                <div class="addMenu">+</div>
              </el-tooltip>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <!-- add family -->
    <BaseFormDialog1
      v-model="familyDialog.visible"
	  v-if="familyDialog.visible"
      :title="familyDialog.title"
      :data="familyDialog.dataList"
      :renderPass="familyDialog.renderPass"
      :ref="familyDialog.ref"
      textReminder="* indicates a required field"
	    @submit="handleAddFamily"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          v-if="scope.data.key === 'email'"
          :ref="scope.data.key"
          v-model="familyDialog.dataRender.email"
          :placeholder="scope.data.placeholder"
          :error="familyDialog.emailErrorObj"
          class="float"
        />
        <baseGGSelect
          v-if="scope.data.key === 'releationship'"
          v-model="familyDialog.dataRender.releationship"
          :placeholder="scope.data.placeholder"
          ref="releationship"
          class="float"
        >
          <md-option
            v-for="(item, index) in releationshipList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
      </template>
      <template slot="footer">
        <baseButton @click="familyDialog.visible = false;familyDialog.dataRender.email='';familyDialog.dataRender.releationship=''"
		  >Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!familyDialog.renderPass"
          @click="handleAddFamily"
          >Add</baseButton
        >
      </template>
    </BaseFormDialog1>
    <!-- <BaseFormDialog
      v-model="familyDialog.visible"
      :title="familyDialog.title"
      :data="familyDialog.dataList"
      @submit="handleAddFamily"
      :renderPass="familyDialog.renderPass"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <baseGGSelect
          v-if="scope.data.key === 'releationship'"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          ref="releationship"
          class="float"
        >
          <md-option
            v-for="(item, index) in releationshipList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
      </template>
    </BaseFormDialog> -->
    <!-- add pickup -->
    <BaseFormDialog1
      v-model="pickupDialog.visible"
	   v-if="pickupDialog.visible"
      :title="pickupDialog.title"
      :data="pickupDialog.dataList"
      @submit="submitPick"
      :submitBtnTxt="pickupDialog.submitBtnTxt"
      :renderPass="pickupDialog.renderPass"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'photo'">
          <div
            class="upload-img"
            @click="isImgCutShow = true"
            :ref="scope.data.key"
          >
            <head-img
              v-if="scope.data.value"
              :src="scope.data.value"
              line-height="88"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
        <baseGGSelect
          v-else-if="scope.data.key === 'releationship'"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          ref="releationship"
          class="float"
        >
          <md-option
            v-for="(item, index) in releationshipList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>

        <PhoneNumber
          v-else-if="scope.data.key == 'phoneNumber'"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          :ref="scope.data.key"
          class="float"
          :inline="true"
		  :isVerifyNull='false'
        ></PhoneNumber>
      </template>
	<!--  <template slot="footer">
	    <baseButton @click="pickupDialog.visible = false">Cancel</baseButton>
	    <baseButton
	      type="primary"
	      :disabled="!pickupDialog.renderPass"
	      @click="submitPick"
	      >Add1</baseButton
	    >
	  </template> -->
    </BaseFormDialog1>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
    <!-- pickup info -->
    <gg-Dialog
      :append-to-body="false"
      :visible.sync="viewPickupInfo"
      :show-close="true"
      title="Pickup"
    >
      <template slot="title">
        <div class="dialogTitle">
          <GgPageHeader
            :subTitle1="
              pickupUserInfo.firstName + ' ' + pickupUserInfo.lastName
            "
            :menuData="['Edit', 'Delete']"
            @menuCallback="pickupMenuBack"
          >
            <!--            :userInfo="pickupUserInfo" -->
            <div slot="avatarHeader">
              <head-img
                class="ggPageTitle"
                :firstName="pickupUserInfo.firstName"
                :lastName="pickupUserInfo.lastName"
                :src="pickupUserInfo.avatar"
                line-height="32"
                style="padding-right: 0"
              />
            </div>
          </GgPageHeader>
        </div>
      </template>
      <div class="dialog-content">
        <ul class="userList">
          <li>
            <label for="" class="label">Name</label>
            <span class="value"
              >{{ pickupInfo.firstName }} {{ pickupInfo.lastName }}</span
            >
          </li>
          <li>
            <label for="" class="label">Relationship</label>
            <span class="value">{{ pickupInfo.releationship }}</span>
          </li>
          <li>
            <label for="" class="label">Phone number</label>
            <span class="value">{{pickupInfo.countryCode}} {{ pickupInfo.phoneNumber }}</span>
          </li>
        </ul>
      </div>
    </gg-Dialog>
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import lightFormat from "date-fns/lightFormat";
export default {
  name: "contacts",
  components: {},
  data() {
    return {
      imgType: "",
      pickupUserInfo: {
        avatar: "",
        firstName: "",
        lastName: "",
      },
      rowId: null, // 修改学生状态用到ID
      studentRowData: {},
      messageObj: {
        Delete: {
          title: "Delete student ?",
          desc:
            "Once the student is deleted, you will not be able to recover the student data.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
          snackbarContent: "Student has been deleted",
        },
        Deferred: {
          title: "Defer student ?",
          desc:
            "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
          cancelTxt: "Cancel",
          confirmTxt: "Defer",
          snackbarContent: "Student has been deferred",
        },
        Enrolled: {
          title: "Return student ?",
          desc:
            "Once returned, This student will move to the enrolled students.",
          cancelTxt: "Cancel",
          confirmTxt: "Return",
          snackbarContent: "Student has been returned",
        },
        Withdrawn: {
          title: "Withdraw student ?",
          desc:
            "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
          cancelTxt: "Cancel",
          confirmTxt: "Withdraw",
          snackbarContent: "Student has been Withdrawn",
        },
        Invite: {
          title: "Invite family ?",
          desc:
            "This will send an email invitation to the family member in order to create an account and connect with the student.",
          cancelTxt: "Cancel",
          confirmTxt: "Send invite",
          snackbarContent: "Email invite has been sent.",
        },
        Remind: {
          title: "Remind family ?",
          desc:
            "This will resend an email invitation to the family member in order to create an account and connect with the student.",
          cancelTxt: "Cancel",
          confirmTxt: "Send remind",
          snackbarContent: "Email remind has been resent.",
        },
      },
      viewPickupInfo: false,
      familyList: [],
      pickupList: [],
      // btnName: "Invite",
      familyDialog: {
        ref: "familyDialog",
        loading: false,
        visible: false,
        title: "Add family",
        isDisabled: true,
        slotData: ["releationship", "email"],
        dataRender: {
          releationship: "",
          email: "",
        },
        emailErrorObj: {
          show: false,
          message: "",
        },
        dataList: [
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S\s]/,
            errorText: "First name required.",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S\s]/,
            errorText: "Last name required.",
          },
          {
            placeholder: "Relationship *",
            key: "releationship",
            value: "",
            list: ["Mother", "Father", "Teacher"],
            renderHtml: true,
          },
          {
            placeholder: "Email*",
            key: "email",
            value: "",
            float: true,
            // reg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
            // errorText: "Email is invalid",
            renderHtml: true,
          },
        ],
        renderPass: true,
      },
      pickupDialog: {
        loading: false,
        visible: false,
        title: "Add pickup",
        submitBtnTxt: "Add",
		renderList:[{
            placeholder: "logo",
            key: "photo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S\s]/,
            errorText: "First name required.",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S\s]/,
            errorText: "Last name required.",
          },
          {
            placeholder: "Relationship *",
            key: "releationship",
            value: "",
            list: ["Mother", "Father", "Teacher"],
            renderHtml: true,
          },
          {
            placeholder: "Phone number",
            key: "phoneNumber",
            float: true,
            value: {
              countryCode: "+65",
              phoneNumber: "",
            },
            renderHtml: true,
          }],
        dataList: [
          {
            placeholder: "logo",
            key: "photo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S\s]/,
            errorText: "First name required.",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S\s]/,
            errorText: "Last name required.",
          },
          {
            placeholder: "Relationship *",
            key: "releationship",
            value: "",
            list: ["Mother", "Father", "Teacher"],
            renderHtml: true,
          },
          {
            placeholder: "Phone number",
            key: "phoneNumber",
            float: true,
            value: {
              countryCode: "+65",
              phoneNumber: "",
            },
            renderHtml: true,
          },
        ],
        renderPass: true,
      },
      familySlotData: ["releationship"],
      pickupSlotData: ["releationship"],
      pickupInfo: {}, // pickup info
      snackbar: {
        isShow: false,
        content: "",
      },
      isImgCutShow: false,
      // studentId: sessionStorage.getItem("studentId"),
      releationshipList: [],
      pickupMessageObj: {
        title: "Delete pickup ?",
        desc:
          "Once the pickup is deleted, you will not be able to recover the data.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
        showClose: false,
        snackbarContent: "Pickup has been deleted",
      },
      rowId: null, // 修改学生状态用到ID
      className: sessionStorage.getItem("className"),
      locationName: sessionStorage.getItem("schoolName"),
    };
  },
  filters: {
    firstUpperCase: Utils.firstUpperCase,
  },
  computed: {
    studentId() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
    },
    pageType() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).type;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    "familyDialog.dataRender.email": function (res) {
      let isPass = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(res);
      if (res.length === 0) {
        this.familyDialog.emailErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.familyDialog.emailErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.familyDialog.emailErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    familyDialog: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          // const isForm = this.$refs.familyDialog.valid(); // 表单其他非插槽元素是否验证通过
		  const isForm = true
          const isRender = res.slotData.every(
            // 插槽数据是否通过校验
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          res.renderPass = isForm && isRender && !this.$refs.email.error.show;

          // console.log(res.renderPass, "render");
        });
      },
    },
    // "familyDialog.dataList": {
    //   handler() {
    //     this.$nextTick(() => {
    //       let renderPass = this.familySlotData.every(
    //         (item) => !!this.$refs[item] && !!this.$refs[item].value
    //       );
    //       console.log(renderPass,"renderPass");
    //       this.familyDialog.renderPass = renderPass;
    //     });
    //   },
    //   deep: true,
    // },
    "pickupDialog.dataList": {
      handler() {
        this.$nextTick(() => {
          let renderPass = this.pickupSlotData.every(
            (item) => !!this.$refs[item] && !!this.$refs[item].value
          );
          this.pickupDialog.renderPass = renderPass && !this.$refs.phoneNumber.isError.show
        });
      },
      deep: true,
    },
  },
  methods: {
    _getData() {
      this.loading = true;
      Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.studentId,
      })
        .then((res) => {
          this.loading = false;
          this.rowId = res.data.id;
          this.studentRowData = { ...res.data };
		 let a = JSON.parse(sessionStorage.getItem('studentInfo')) 
		 a.status=res.data.status
		 sessionStorage.setItem('studentInfo',JSON.stringify(a))
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
            this.handleSuspend(messageObj.snackbarContent);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    _getFamilyData() {
      this.familyDialog.loading = true;
      Ajax.get("/usermanage/parent/getListByStudentId", {
        studentId: this.studentId,
      })
        .then((res) => {
          this.familyDialog.loading = false;
          this.familyList = res.data;
		  let a = JSON.parse(sessionStorage.getItem('studentInfo')) 
		  this.familyList.forEach((item)=>{
			  if(a.status=='Withdrawn'){
				 item.parentStatus=='Disconnected'
			  }else if(item.parentStatus=='Disconnected'){
				   // item.parentStatus='Invite'
			  }
			console.log(!this.$buttonPermission('Students contacts', 'Full') && item.parentStatus!='Connected')
				if(!this.$buttonPermission('Students contacts', 'Full') && item.parentStatus!='Connected'){
					item.parentStatus='Disconnected'
				}
		  })
        })
        .catch(() => {
          this.familyDialog.loading = false;
        });
    },
    _getPickupData() {
      this.pickupDialog.loading = true;
      Ajax.get("/usermanage/pickup/selectByStudentId", {
        studentId: this.studentId,
      })
        .then((res) => {
          this.pickupDialog.loading = false;
          this.pickupList = res.data;
        })
        .catch(() => {
          this.pickupDialog.loading = false;
        });
    },
    linkToParent(item) {
      this.setBreadcrumData(item);
	  let baseData = {
	          companyId: this.user.companyId,
	          schoolId: this.user.locationId,
	          studentId: this.studentId,
			  releationship:item.releationship
	        };
			sessionStorage.setItem('currentParentInfo', JSON.stringify(baseData))
      this.$router.push({
        name: "parentPersonalInfo",
        query: { id: item.id, studentId: this.studentId },
      });
    },
	linkToParent1(item) {
	  // this.setBreadcrumData(item);
	  this.$store.commit("SET_BREADCRUMDATA", [
	    {
	      routeName: "people",
	      name: "People",
	    },
	    {
	      routeName: "studentsInfo",
	      name: `${this.studentRowData.firstName} ${this.studentRowData.lastName}`,
	    },
	    {
	      name: `${item.firstName} ${item.lastName}`,
	    },
	  ]);
	  this.$router.push({
	    name: "peopleParentPersonalInfo",
	    query: { id: item.id, studentId: this.studentId },
	  });
	},
    setBreadcrumData(item) {
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "students",
          name: "Students",
        },
        {
          routeName: "studentsInfo",
          name: `${this.studentRowData.firstName} ${this.studentRowData.lastName}`,
        },
        {
          name: `${item.firstName} ${item.lastName}`,
        },
      ]);
    },
    handleFun(action, item) {
		// if(action == 'Connected' || action == 'Disconnected'){
		// return false	
		// }
      let callback = () => {
        // invite fun
        this.handleInvite(item);
      };
      if (action !== "Remind") action = "Invite";
      // if (action === "Remind") {
      //   callback = () => {
      //     // invite fun
      //     this.handleRemind(item);
      //   };
      // }

      this.openMessageBox(this.messageObj[action], callback);
    },
    handleInvite(item) {
      // invite
      let parmas = {
        id: item.id,
        email: item.email,
      };
      Ajax.post("/usermanage/email/inviteSendEmail", parmas).then((res) => {
        this._getFamilyData();
      });
    },
    // handleRemind() {
    //   // remind
    //   console.log("remind");
    // },
    openFamilyDialog() {
      this.familyDialog.visible = true;
      this.familyDialog.dataList.forEach((item) => {
        item.value = "";
      });
    },
    previewDetail(item) {
      this.viewPickupInfo = true;
      this._getPickupDetail(item);
    },
    openPickupDialog() {
      this.pickupDialog.visible = true;
      this.pickupDialog.title = "Add pickup";
      this.pickupDialog.dataList.forEach((item) => {
        item.value = "";
        if (item.key == "phoneNumber") {
          item.value = {
            countryCode: "+65",
            phoneNumber: "",
          };
        }
      });
    },
    submitPick() {
      if (this.pickupDialog.title === "Add pickup") {
        this.handleAddPickup();
      } else {
        this.handleUpdatePickup();
      }
    },
    handleAddFamily() {
      let obj = {};
      let formData = {};
      this.loading = true;
      this.$refs.familyDialog.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });
      // let formData = {
      //   firstName: "",
      //   lastName: "",
      //   releationship: "",
      //   email: "",
      // };
      let baseData = {
        companyId: this.user.companyId,
        schoolId: this.user.locationId,
        studentId: this.studentId,
      };
      // this.familyDialog.dataList.forEach((item) => {
      //   formData[item.key] = item.value;
      // });
      formData = Object.assign({}, obj, this.familyDialog.dataRender, baseData);
      
      Ajax.post("/usermanage/parent/saveParent", formData)
        .then((res) => {
          this.loading = false;
          if (res.code === "0000") {
            this._getFamilyData();
			this.familyDialog.dataRender.email=''
			this.familyDialog.dataRender.releationship=''
            this.handleSuspend("Family member has been added");
            this.familyDialog.visible = false;
          } else {
            if (res.code==9999) {
              this.familyDialog.emailErrorObj = {
                show: true,
                // message: res.message,
                message: "Email already exists",
              };
            }
          }
          // if (res.code !== "0000") {
          //   // this.handleSuspend(res.message);
          // } else {
          //   this.handleSuspend("Family member has been added");
          // }
          // this._getFamilyData();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleAddPickup() {
      this.loading = true;
      let formData = {
        studentId: this.studentId,
        familyId: "",
        firstName: "",
        lastName: "",
        countryCode: "",
        phoneNumber: "",
        photo: "",
        releationship: "",
      };

      this.pickupDialog.dataList.forEach((item) => {
        if (item.key == "phoneNumber") {
          formData["countryCode"] = item.value.countryCode;
          formData["phoneNumber"] = item.value.phoneNumber;
        } else {
          formData[item.key] = item.value;
        }
      });

      Ajax.post("/usermanage/pickup/save", formData)
        .then((res) => {
          this.loading = false;
          if (res.code !== "0000") {
            this.handleSuspend(res.message);
          } else {
            this.handleSuspend("Pickup has been added");
          }
          this._getPickupData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleUpdatePickup() {
      this.loading = true;
      let formData = {
        id: this.pickupInfo.id,
        familyId: "",
        firstName: "",
        lastName: "",
        countryCode: "",
        phoneNumber: "",
        photo: "",
        releationship: "",
      };

      this.pickupDialog.dataList.forEach((item) => {
        if (item.key == "phoneNumber") {
          formData["countryCode"] = item.value.countryCode;
          formData["phoneNumber"] = item.value.phoneNumber;
        } else {
          formData[item.key] = item.value;
        }
      });

      Ajax.post("/usermanage/pickup/updatePickup", formData)
        .then((res) => {
          this.loading = false;
          if (res.code !== "0000") {
            this.handleSuspend(res.message);
          } else {
            this.handleSuspend("Pickup has been edited");
          }
          this._getPickupData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.pickupDialog.dataList.find(
            (item) => item.key === "photo"
          ).value = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    _getSettingList(dataLevel, dataType, datalistName) {
      let data = {
        userId: this.user.userId,
        dataLevel,
        dataType,
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this[datalistName] = response || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getPickupDetail(item) {
      Ajax.get("/usermanage/pickup/selectById", {
        id: item.id,
      }).then((res) => {
        let { photo, firstName, lastName, countryCode, phoneNumber } = res.data;
        this.pickupInfo = { ...res.data };
        this.pickupUserInfo = {
          avatar: photo,
          firstName: firstName,
          lastName: lastName,
        };
        this.pickupDialog.dataList.forEach((item) => {
          item.value = res.data[item.key];
          if (item.key == "phoneNumber") {
            item.value = {
              countryCode: countryCode ? countryCode : "+65",
              phoneNumber: phoneNumber ? phoneNumber : "",
            };
          }
        });
      });
    },
    pickupMenuBack(action) {
      if (action === "Edit") {
        this.viewPickupInfo = false;
        this.pickupDialog.visible = true;
        this.pickupDialog.title = "Edit pickup";
        this.pickupDialog.submitBtnTxt = "Save";
      } else if (action === "Delete") {
        this.pickupDialog.title = "Add pickup";
        let callback = () => {
          this._deletePickup();
        };
        this.openMessageBox(this.pickupMessageObj, callback);
      }
    },
    _deletePickup() {
      let formData = {
        id: this.pickupInfo.id,
        status: "Deleted",
      };

      this.loading = true;
      Ajax.post("/usermanage/pickup/updatePickup", formData)
        .then((res) => {
          this.loading = false;
          if (res.code == "0000") {
            this.handleSuspend(`Pickup has been deleted.`);
          } else {
            this.handleSuspend(res.message);
          }
          this.viewPickupInfo = false;
          this._getPickupData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this._getData();
    this._getFamilyData();
    this._getPickupData();
    this._getSettingList("student", "relationship", "releationshipList");
  },
};
</script>
<style lang='less' scoped>
/deep/.phone-number-content {
  margin-bottom: 0;
}
.section-list {
  min-height: 132px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 22px;
        color: #5f6368;
      }
    }
    &-right {
    }
  }
  &-main {
    padding: 0 20px;
    margin: 20px 0 0;
    color: #202124;
    font-family: Roboto;
    font-size: 14px;
    overflow-y: auto;
    .content {
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      width: 136px;
      // height: 116px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .starImg {
        width: 17px;
        height: 16px;
        position: absolute;
        top: 0;
        right: 29px;
        z-index: 9;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        margin: 9px 0;
        width: 100%;
        height: 19px;
        line-height: 19px;
        color: #1a73e8;
        font-family: Roboto-medium;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
      }
      .connections {
        margin-bottom: 9px;
        color: #5f6368;
      }
      /*.btn {*/
      /*  height: 24px;*/
      /*  padding: 0 13px;*/
      /*  !*border: 1px solid #1a73e8;*!*/
      /*  border-radius: 4px;*/
      /*  color: #EA4335;*/
      /*  font-family: Roboto-medium;*/
      /*  cursor: inherit;*/
      .btn {
        /* height: 24px; */
        padding: 0 13px;
        border: 1px solid #1a73e8;
        border-radius: 4px;
        color: #1a73e8;
        font-family: Roboto-medium;
        cursor: pointer;
        &.green {
          color: #0b8043;
          border: 0;
          cursor: inherit;
        }
        &.red {
          color: #ea4335;
          border: 0;
          cursor: inherit;
        }
      }
      .addMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 88px;
        line-height: 88px;
        border: 1px solid #b4b4b4;
        border-radius: 50%;
        color: #b4b4b4;
        font-size: 34px;
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
        // &:hover .not-allowed {
        //   cursor: not-allowed;
        //   background-color: #eee;
        // }
      }
      // .addBtn {
      //   display: none;
      //   font-family: Roboto;
      //   margin-top: 8px;
      //   font-size: 10px;
      //   width: 64px;
      //   height: 22px;
      //   padding: 0;
      //   line-height: 22px;
      //   background-color: #6f6f6f;
      //   color: #fff;
      //   text-align: center;
      //   border: 0;
      //   border-radius: 2px;
      // }
    }
  }
}
// pickup userinfo
/deep/.gg_dialog .el-dialog .el-dialog__header {
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
}
/deep/.ggPageTitle-right {
  position: absolute;
  top: 18px;
  right: 36px;
}
/deep/.phone-number-content {
  padding-top: 20px;
}
/deep/.phone-form .phone-form-input .md-input {
  width: 100px;
}
.userList {
  li {
    display: flex;
    font-size: 16px;
    font-family: Roboto;
    color: #202124;
    margin-bottom: 32px;
    .label {
      padding-right: 100px;
      width: 252px;
    }
  }
}
/deep/ .ggPageTitle {
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  padding-right: 15px;
  border: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  .ggPageTitle-left {
    align-items: center;
  }
  .titleInfo {
    padding-left: 15px;
  }
}
/deep/.userList li{
	font-size: 14px;
	/* font-family: Roboto; */
	/* color:rgba(0,0,0,0.8); */
}
 /deep/.el-dialog{
	 width: 585px;
 }
.Connected{
	border: none!important;
	color: green!important;
	pointer-events: none;
	font-family: Roboto!important;
}
.Disconnected{
	border: none!important;
	color: red!important;
	pointer-events: none;
	font-family: Roboto!important;
}

</style>
